export default function ClockIconSVG() {
  return (
    <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="clock-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(0.200000, 0.200000)" fill="#757A82" fillRule="nonzero">
          <path
            d="M6.7875,0.96 C3.5675,0.96 0.96,3.57333333 0.96,6.79333333 C0.96,10.0133333 3.5675,12.6266667 6.7875,12.6266667 C10.0133333,12.6266667 12.6266667,10.0133333 12.6266667,6.79333333 C12.6266667,3.57333333 10.0133333,0.96 6.7875,0.96 Z M6.79333333,11.46 C4.215,11.46 2.12666667,9.37166667 2.12666667,6.79333333 C2.12666667,4.215 4.215,2.12666667 6.79333333,2.12666667 C9.37166667,2.12666667 11.46,4.215 11.46,6.79333333 C11.46,9.37166667 9.37166667,11.46 6.79333333,11.46 Z"
            id="Shape"
          ></path>
          <polygon
            id="Path"
            points="7.125 3.91666667 6.25 3.91666667 6.25 7.41666667 9.3125 9.25416667 9.75 8.53666667 7.125 6.97916667"
          ></polygon>
        </g>
      </g>
    </svg>
  )
}
